<template>
	<ul class="menu-nav">
		<router-link to="/user/liste" v-slot="{ href, navigate, isActive, isExactActive }" v-if="$store.back.getUser().role_id === 1">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
				<a :href="href" class="menu-link" @click="navigate"> <i class="menu-icon flaticon2-user"></i>  <span class="menu-text">Utilisateurs</span>
				</a>
			</li>
		</router-link>
		<router-link to="/lead/liste" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
				<a :href="href" class="menu-link" @click="navigate"> <i class="menu-icon fas fa-gem"></i>  <span class="menu-text">{{$store.back.getText('leads')}}</span>
				</a>
			</li>
		</router-link>
	</ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
	data(){
		return {
			'role':'isUser'
		};
	},
	beforeMount() {
		this.role = this.$store.back.getUser().role_id === 1 ? 'isAdmin':'isUser'
	},
	mounted() {
	  //do something after mounting vue instance
	}
};
</script>
